import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "illustrations",
      "style": {
        "position": "relative"
      }
    }}>{`Illustrations`}</h3>
    <p>{`By introducing illustrations we're bringing more brand-identity to Chameleon.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="Illustrations" description="Read more about illustrations" to="/foundations/illustrations" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "mediahuis-scripts",
      "style": {
        "position": "relative"
      }
    }}>{`mediahuis-scripts`}</h3>
    <p>{`With the introduction of mediahuis-scripts, we've improved and simplified our
setup. We've added extra output formats and all our react code is pre-compiled.
This also brings faster build-times and less setup for react-developers.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="Getting started for developers" description="Read more about react development setup" to="/getting-started/for-developers" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "full-changelog",
      "style": {
        "position": "relative"
      }
    }}>{`Full changelog`}</h3>
    <p>{`Check out the full changelog for more information.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v1.4.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h3>
    <p>{`After the release of `}<inlineCode parentName="p">{`v1.4.0`}</inlineCode>{`, we've been busy looking for the next step to
take, ensuring it is fully in line with the whole vision around HUB, we will
start a track to better integrate native apps and webmasters, beginning in 2021.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      